import React from "react"
import { HeaderStyle, MyMojoTemplate, PageTheme } from "templates/MyMojoTemplate"
import styled from "styled-components"
import devices from "styles/devices"

const Root = styled.div`
    flex-direction: column;
    margin: 16px 40px;
    line-height: 24px;
    max-width: 1024px;

    @media ${devices.bp_lg} {
        margin: 16px auto;
    }
`

const Header = styled.h1`
    margin-bottom: 1em;
`

const Subheader = styled.h4`
    margin: 1em 0;
`

const UnavailableMessageContainer = styled.div`
    background: ${({ theme }) => theme.colors.peach[100]};
    border-radius: 8px;
    padding: 0.5em 1.5em;
    margin-top: 1em;
`

const Anchor = styled.a`
    color: ${({ theme }) => theme.colors.vivid[400]};
`


export const ProtectionReviewLandingPage = () => {
  return (
    <MyMojoTemplate
      stickyHeader={false}
      colourTheme={PageTheme.Primary}
      headerStyle={HeaderStyle.Transparent}
      content={
        <Root>
          <Header>Your Mojo mortgage protection appointment</Header>
          <Subheader>Thanks for coming to complete your pre-appointment protection questions.</Subheader>
          <UnavailableMessageContainer>
            <p>Our online form is currently unavailable, but don’t worry, your protection expert will go over these
              questions in your protection appointment to make sure you get appropriate and accurate advice.</p>
          </UnavailableMessageContainer>
          <p>Don’t forget to complete your remaining pre-appointment actions:</p>
          <Subheader>Gather details of any existing cover</Subheader>
          <p>Gather and bring information about any existing insurance policies and work benefits you may have to the
            appointment, including:</p>
          <ul>
            <li>Life cover</li>
            <li>Critical illness cover</li>
            <li>Income protection</li>
            <li>Paid sick leave</li>
            <li>Death in service</li>
          </ul>
          <Subheader>Watch our short protection explainer</Subheader>
          <p>Take a few minutes to watch this <b><Anchor
            href="https://vimeo.com/user14855064/review/449332786/6aa3a4fbb0">short
            video</Anchor></b> from one of our protection insurance partners on what protection might mean for you and
            your family.</p>
          <Subheader>Team Mojo <Anchor
            href="mailto:advice@mojomortgages.com">advice@mojomortgages.com</Anchor></Subheader>
        </Root>
      } />
  )
}
